import { useCommonStore } from 'stores/common-store'
import { useWatchlistStore } from 'stores/watchlist-store'
import {
  parseRouteAssetViewProps,
  parseRouteAuthorViewProps,
  parseRouteListViewProps,
  ROUTE_NAME_ASSET,
  ROUTE_NAME_AUTHOR,
  ROUTE_NAME_EXPLORE,
  ROUTE_NAME_VIEW
} from 'src/router/routes'
import { RouteLocationNormalized } from 'vue-router'
import { ViewFilter } from 'boot/axios'
import { format } from 'quasar'
import * as EntityHelper from 'src/helper/EntityHelper'
import { EntityEnum } from 'src/helper/EntityHelper'
import { getViewFilterFromProps } from 'src/helper/RouterHelper'
import { ListEntityType } from 'src/types/ListEntityType'

export function capitalizeFirstLetter (str: string): string {
  return str
    // split words
    .split(' ')
    // capitalize first letter in every word
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    // rejoin words
    .join(' ')
}

// Function to generate SHA-256 hash
export async function sha256 (str: string): Promise<string> {
  // We will use the SubtleCrypto API in browsers that support it
  const buffer = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(str))
  const hashArray = Array.from(new Uint8Array(buffer))
  return hashArray.map(byte => ('00' + byte.toString(16)).slice(-2)).join('')
}

// Function to create identifier from multiple values
export async function createIdentifier (...values: unknown[]): Promise<string> {
  // Concatenate all values into a single string
  const combinedValue = values.map(value => typeof value === 'object' ? JSON.stringify(value) : value).join('')
  // Generate SHA-256 hash for the combined value
  return await sha256(combinedValue)
}

export interface HeadlineInfo {
  icon: string,
  type: string,
  entityName: string,
  singleEntityId?: number,
  entityType?: EntityEnum
}

export function createHeadlineInfoForRoute (route: RouteLocationNormalized): HeadlineInfo | null {
  const { capitalize } = format
  const commonStore = useCommonStore()
  const watchlistStore = useWatchlistStore()

  switch (route.name) {
  case ROUTE_NAME_AUTHOR: {
    const parsedAuthorRouteProps = parseRouteAuthorViewProps(route)
    const authorString = parsedAuthorRouteProps.name && parsedAuthorRouteProps.authorSource
      ? parsedAuthorRouteProps.name + ' @ ' + parsedAuthorRouteProps.authorSource
      : ''
    if (authorString.length < 1) {
      // TODO: log if assetName is empty
      console.error('historyStore::createLabel, could not resolve author', parsedAuthorRouteProps)
    }
    return {
      icon: 'spi-user',
      type: 'Author',
      entityName: authorString
    }
  }

  case ROUTE_NAME_VIEW: {
    const parsedRouteListViewProps = parseRouteListViewProps(route)
    const viewFilter = getViewFilterFromProps(parsedRouteListViewProps)
    const watchlistView = watchlistStore.findViewByViewFilter(viewFilter)
    if (watchlistView) {
      const info: HeadlineInfo = {
        icon: 'spi-binoculars',
        type: 'View',
        entityName: watchlistView.name
      }

      // Special handling for indexes on the watchlist
      if (parsedRouteListViewProps.list.length === 1) {
        info.singleEntityId = parsedRouteListViewProps.list[0]
      }

      return info
    }

    const entityType = getEntityType(viewFilter)
    let icon = 'spi-list'
    let type = 'View'
    let singleEntityId: number | undefined
    if (entityType) {
      switch (entityType) {
      case ListEntityType.COUNTRY:
        icon = 'spi-globe'
        type = ListEntityType.COUNTRY
        break
      case ListEntityType.EXCHANGE:
        icon = commonStore.getExchangeById(parsedRouteListViewProps.exchange[0])?.icon || 'spi-globe'
        type = ListEntityType.EXCHANGE
        break
      case ListEntityType.LIST:
        singleEntityId = parsedRouteListViewProps.list[0]
        icon = commonStore.getListById(parsedRouteListViewProps.list[0])?.icon || 'spi-list'
        type = ListEntityType.LIST
        break
      case ListEntityType.REGION:
        icon = 'spi-globe'
        type = ListEntityType.REGION
        break
      case ListEntityType.SECTOR:
        icon = commonStore.getSectorIconById(parsedRouteListViewProps.sector[0]) || 'spi-globe'
        type = ListEntityType.SECTOR
        break
      case ListEntityType.ASSET:
        icon = commonStore.getTitleIconById(parsedRouteListViewProps.asset[0]) || 'spi-building'
        type = ListEntityType.ASSET
        break
      case ListEntityType.KEYEVENT:
        icon = commonStore.getKeyEventIconById(parsedRouteListViewProps.keyEvent[0]) || 'spi-globe'
        type = ListEntityType.KEYEVENT
        break
      case ListEntityType.TYPE:
        icon = 'spi-chart-line'
        type = ListEntityType.TYPE
        break
      }
    }
    type = capitalize(type)
    const entityName = commonStore.createNameFromTitleFilter(parsedRouteListViewProps)
    const searchQueryString = parsedRouteListViewProps.searchQuery

    return {
      icon,
      type,
      entityName: searchQueryString ? '\'' + searchQueryString + '\' ' + entityName : entityName,
      singleEntityId
    }
  }

  case ROUTE_NAME_EXPLORE: {
    const name = 'Explore Assets'
    return {
      icon: 'spi-searchengin',
      entityName: name,
      type: ''
    }
  }

  case ROUTE_NAME_ASSET: {
    const parsedRouteAssetProps = parseRouteAssetViewProps(route)

    const title = commonStore.getTitleById(parsedRouteAssetProps.title_id || -1)
    if (!title) {
      // TODO: log if assetName is empty
      console.error('historyStore::createLabel, could not resolve title id', parsedRouteAssetProps)
    }
    const type = title?.type ? capitalizeFirstLetter(title?.type.replace('_', ' ')) : 'Asset'

    return {
      icon: title?.icon || 'spi-building',
      entityName: title?.n || '',
      type: capitalize(type),
      singleEntityId: title?.id || undefined,
      entityType: title?.id ? EntityHelper.getEntityType(title.id) : undefined
    }
  }
  }

  return null
}

// Check if the titleFilter contains a single Entity
export function getEntityType (viewFilter: ViewFilter) : ListEntityType|undefined {
  if (viewFilter === undefined) {
    return undefined
  }
  const viewFilterValue = viewFilter
  let foundEntityType
  Object.values(ListEntityType).forEach(entityType => {
    const isEntity = Object.keys(viewFilterValue).every(type => {
      if (viewFilterValue === undefined) {
        return false
      }
      if (!isPropertyOfTitleFilter(type, viewFilterValue)) {
        return false
      }
      const filter = viewFilterValue[type] as number[]
      if (!filter) {
        return false
      }
      if (type === entityType) {
        return filter.length === 1
      }
      return filter.length === 0
    })
    if (isEntity) {
      foundEntityType = entityType
    }
  })
  return foundEntityType || undefined
}

export function isPropertyOfTitleFilter (prop: string, titleFilter: ViewFilter): prop is keyof ViewFilter {
  return prop in titleFilter
}

export function sanitizeString (input: string): string {
  // This regex keeps letters (\p{Letter}), numbers (\p{Number}), and dots (\.)
  // and replaces everything else with an empty string.
  // Flags:
  // u - enables Unicode mode, necessary for using Unicode property escapes
  return input.replace(/[^\p{Letter}\p{Number}.]/gu, '')
}

export function getTrackingIdentifier (identifier: string): string {
  return identifier.toLowerCase().replaceAll(' ', '-')
}
