import { defineStore } from 'pinia'
import { api } from 'boot/axios'
import AuthenticationService, { LoginCredentials, UserData } from 'src/services/AuthenticationService'
import { Ref, ref } from 'vue'
import { useRouter } from 'vue-router'
import NotificationService from 'src/services/NotificationService'
import { Auxiliary } from 'src/helper/Auxiliary'
import * as Sentry from '@sentry/vue'

const STORE_CURRENT_VERSION = 1

const USER_GUEST = 'guest'

const guestUser = {
  username: USER_GUEST
} as UserData

export const useAuthUserStore = defineStore('auth/user', {
  persist: {
    storage: localStorage,
    debug: true,
    afterRestore: context => {
      const restoredVersion = context.store.$state.version || 1

      if (restoredVersion < STORE_CURRENT_VERSION) {
        console.log('store was reset, restored version below current version', restoredVersion, STORE_CURRENT_VERSION)
        context.store.$reset()
      }

      if (context.store.$state.credentials) {
        // recreate class relation on hydration, so we can call `toAuthString`
        context.store.$state.credentials = new LoginCredentials(
          context.store.$state.credentials.username,
          context.store.$state.credentials.password)

        api.defaults.headers.common.Authorization = 'Basic ' + context.store.$state.credentials.toAuthString()

        const authService = new AuthenticationService()
        // We reload the userdata after hydration
        authService.login(context.store.$state.credentials, true).then(result => {
          if (result.success && result.userData != null) {
            context.store.setUserData(result.userData, context.store.$state.credentials, true)
          } else {
            context.store.$reset()
          }
        }).catch(() => {
          context.store.$reset()
        })
      }
    }
  },
  state: () => {
    const user : UserData = Auxiliary.cloneObject(guestUser)
    const credentials : Ref<LoginCredentials|null> = ref(null)
    // TODO: logout user after certain time?
    const loggedInDate : Ref<Date|null> = ref(null)
    const version = STORE_CURRENT_VERSION
    return {
      loggedInDate,
      user,
      credentials,
      version
    }
  },
  getters: {
    fullName: (state) => `${state.user.first_name} ${state.user.last_name}`,
    initials: (state) => `${state.user.first_name[0].toUpperCase()}${state.user.last_name[0].toUpperCase()}`,
    isLoggedIn: (state) => state.user.username !== guestUser.username,
    hasValidSubscription: (state) => state.user.username !== guestUser.username &&
      state.user.valid_until > ((new Date()).getTime() / 1000),
    language: (state) => state.user.language,
    locale: (state) => {
      switch (state.user.language) {
      case 'de':
        return 'de-DE'
      case 'en':
      case undefined:
        return 'en-US'
      default:
        Sentry.captureMessage(
          `User language not in Mapping. User: ${state.user.username}, Language: ${state.user.language}`)
        return 'en-US'
      }
    },
    getCredentials: (state) => state.credentials,
    maxTimeSpanDays: (state) => state.user.timespan_days || 366,
    historyMonths: (state) => state.user.history_months,
    username: (state) => state.user.username,
    firstName: (state) => state.user.first_name,
    lastName: (state) => state.user.last_name,
    pendingSubscriptions: (state) => state.user?.pendingSubscriptions || []
  },
  actions: {
    async setUserData (userData: UserData, credentials: LoginCredentials, triggerNotifications: boolean) {
      this.credentials = credentials
      api.defaults.headers.common.Authorization = 'Basic ' + credentials.toAuthString()
      this.updateUserData(userData)

      if (triggerNotifications) {
        const router = useRouter()
        const notificationService = new NotificationService(router)
        if (userData.username !== guestUser.username && userData.valid_until > ((new Date()).getTime() / 1000)) {
          if (userData.validTime < 60 * 60 * 24 * 7) {
            notificationService.notifySoonExpiration(userData.valid_until,
              userData?.pendingSubscriptions?.length > 0
            )
          }
        } else {
          notificationService.notifySubscriptionExpired()
        }
      }
    },
    updateUserData (userData: UserData) {
      this.user = userData
      this.loggedInDate = new Date()
      Sentry.setUser({ id: userData.user_id })
    },
    async logout () {
      delete api.defaults.headers.common.Authorization
      this.credentials = null
      this.user = Auxiliary.cloneObject(guestUser)
      this.loggedInDate = null
      Sentry.setUser(null)
    }
  }
})
