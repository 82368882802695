import { defineStore } from 'pinia'
import {
  ROUTE_NAME_ASSET,
  ROUTE_NAME_AUTHOR,
  ROUTE_NAME_EXPLORE,
  ROUTE_NAME_VIEW
} from 'src/router/routes'
import { RouteLocationNormalized } from 'vue-router'
import { Ref, ref } from 'vue'
import { createHeadlineInfoForRoute, HeadlineInfo } from 'src/helper/StringHelper'

export interface PageHistoryEntry {
  route: RouteLocationNormalized;
  headlineInfo: HeadlineInfo,
  isCurrentPageAndQuery: boolean
}

const maxEntries = 10

export const useHistoryStore = defineStore('historyStore', () => {
  const visitedPages : Ref<RouteLocationNormalized[]> = ref([])
  const routesToRecord = [
    ROUTE_NAME_VIEW,
    ROUTE_NAME_AUTHOR,
    ROUTE_NAME_ASSET,
    ROUTE_NAME_EXPLORE
  ]

  function addHistoryEntry (route: RouteLocationNormalized): void {
    if (!route.name) {
      return
    }

    if (!routesToRecord.includes(route.name.toString())) {
      return
    }

    // TODO: maybe do not check full query, if query contains from, to timestamps
    //  just compare some defined parts like id, ...
    // TODO: create or use global helper function to normalize query parameters
    // Check for duplicate entry
    const existingIndex = visitedPages.value.findIndex((entry) =>
      entry.name === route.name &&
        JSON.stringify(entry.query) === JSON.stringify(route.query))

    if (existingIndex > -1) {
      // If duplicate, move to the start of the array
      const existingEntry = visitedPages.value.splice(existingIndex, 1)[0]
      visitedPages.value.unshift(existingEntry)
    } else {
      if (visitedPages.value.length >= maxEntries) {
        // Ensure we don't exceed maxEntries by removing the last entry if necessary
        visitedPages.value.pop()
      }
      // Add new entry at the start of the array
      visitedPages.value.unshift(route)
    }
  }

  function getFilteredHistory () : PageHistoryEntry[] {
    const entries = visitedPages.value.map((route: RouteLocationNormalized, index: number) => {
      const headlineInfo = createHeadlineInfoForRoute(route)

      return headlineInfo ? { route, headlineInfo, isCurrentPageAndQuery: index === 0 } : null
    })

    return entries.filter(x => x != null) as PageHistoryEntry[]
  }

  return { addHistoryEntry, getFilteredHistory }
})
