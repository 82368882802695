import { computed, Ref, ref, watch } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { WebSocketAuthStatus, webSocketStore } from 'stores/web-socket-store'
import { api, FilterSetting, ViewFilter, ViewFilterEntities } from 'boot/axios'
import { useAuthUserStore } from 'stores/auth-user-store'
import {
  AuthorShortInfo,
  BuzzSentiment,
  CountriesInner,
  ListsInner,
  QueryInfo,
  WatchlistContent,
  WatchlistItemsToAdd,
  WatchlistsApiFactory,
  WatchlistsInventoryInner
} from '@stockpulse/typescript-axios'
import { Auxiliary } from 'src/helper/Auxiliary'
import {
  AssetEntityType,
  ExchangeData,
  InventorySector,
  InventoryTitle,
  KeyEvent,
  useCommonStore
} from 'stores/common-store'
import { getEntityType } from 'src/helper/StringHelper'
import { SubscriptionId, useRealTimeDataStore } from 'stores/realtime-data-store'
import { ListEntityType } from 'src/types/ListEntityType'
import { useAsyncApiData } from 'src/composables/useAsyncApiData'
import { CancellationPolicy } from 'src/services/AxiosRequestManager'

export interface BuzzAlert {
  email: boolean,
  push: boolean,
  popup: boolean
}
export interface KeyEventAlert {
  key_events: number[],
  email: boolean,
  push: boolean,
  popup: boolean
}
export interface Author {
  name: string,
  source: string
}
export interface AuthorAlert {
  authors: Author[],
  email: boolean,
  push: boolean,
  popup: boolean
}
export interface Alerts {
  buzz?: BuzzAlert,
  key_events?: KeyEventAlert,
  authors?: AuthorAlert
}

export interface APIWatchlistFilter {
  name: string,
  filter: FilterSetting[],
  alerts: Alerts
}
export enum ViewType {
  VIEW = 'view',
  ASSET = 'asset'
}

export interface WatchlistView {
  watchlistId: number,
  name: string,
  filter: ViewFilter,
  alerts: Alerts,
  type: ViewType
}

export type WatchlistAuthor = AuthorShortInfo & { watchlistId: number }

export interface WatchlistData {
  id: number;
  n: string;
  titles: Array<BuzzSentiment>;
  authors?: Array<AuthorShortInfo>;
  queries?: Array<QueryInfo>;
  filters?: Array<WatchlistView>
}

export interface APIWatchlistResponse {
  id: number;
  n: string;
  titles: Array<BuzzSentiment>;
  authors?: Array<AuthorShortInfo>;
  queries?: Array<QueryInfo>;
  filters?: Array<APIWatchlistFilter>
}

export enum filterV3FieldNames {
  sector = 'sector',
  group = 'group',
  industry = 'industry',
  subindustry = 'subindustry',
  // TODO exchange could use column 'listed_at'
  exchange = 'pri_exchange.exchange_id',
  list = 'listed_in',
  country = 'country',
  asset = 'id',
  type = 'type',
  keyEvent = 'key_events'
}

export interface UpdateFilterData {
  name: string,
  alerts: Alerts
}

export type FilterData = UpdateFilterData & { filter: FilterSetting[] }

export type WatchlistItemToAdd = WatchlistItemsToAdd & { filters?: FilterData[] }

export type WatchlistContentData = WatchlistContent & { filters?: APIWatchlistFilter[] }

export const useWatchlistStore = defineStore('watchlistStore', () => {
  const webSocket = webSocketStore()
  const authUserStore = useAuthUserStore()
  const commonStore = useCommonStore()
  const realtimeDataStore = useRealTimeDataStore()
  const { authStatus } = storeToRefs(webSocket)
  const { hasValidSubscription } = storeToRefs(authUserStore)
  const watchlistsApiFactory = WatchlistsApiFactory(undefined, '/v6', api)

  const isInitialized = ref(false)
  const {
    state: watchLists,
    load: loadWatchLists
  } = useAsyncApiData<APIWatchlistResponse[]>(undefined, [])

  const subscriptionIds: Ref<SubscriptionId[]> = ref([])

  const onHasValidSubscription = (newValue: boolean): void => {
    if (newValue) {
      loadData()
    }
  }

  watch(hasValidSubscription, onHasValidSubscription, { immediate: true })

  function emitToWebsocket (): void {
    emitWatchlistsToWebsocket()
  }

  const views = computed(() => {
    if (watchLists.value.value === undefined || watchLists.value.value?.length === 0) {
      return []
    }
    const foundViews : WatchlistView[] = []
    watchLists.value.value?.forEach(watchList => {
      watchList.filters?.forEach(view => {
        if (!view.filter) {
          return
        }

        const filterView = Auxiliary.parseFilterSettingToViewFilter(view.filter)
        const entityType = getEntityType(filterView)
        foundViews.push({
          watchlistId: watchList.id,
          name: view.name,
          alerts: view.alerts,
          filter: filterView,
          type: entityType === ListEntityType.ASSET ? ViewType.ASSET : ViewType.VIEW
        })
      })
    })

    return foundViews
  })

  watch(() => views, async () => {
    realtimeDataStore.unsubscribeTitleUpdates(subscriptionIds.value)

    if (views.value.length === 0) {
      return
    }
    const titleIds = views.value
      .filter(view => view.type === ViewType.ASSET)
      .map(view => getTitleFromView(view)?.id)
      .filter(id => id !== undefined) as number[]

    if (titleIds.length === 0) {
      return
    }
    subscriptionIds.value.push(realtimeDataStore.subscribeTitleUpdates([...new Set(titleIds)]))
  }, { deep: true })

  const authors = computed(() => {
    if (watchLists.value.value === undefined || watchLists.value.value?.length === 0) {
      return []
    }
    const foundAuthors : WatchlistAuthor[] = []
    watchLists.value.value?.forEach(watchList => {
      watchList.authors?.forEach(author => {
        foundAuthors.push(Object.assign({}, author, { watchlistId: watchList.id }))
      })
    })

    return foundAuthors
  })

  function emitWatchlistsToWebsocket (): void {
    // Subscribe to Watchlists
    const allWatchLists = watchLists.value.value
    if (!allWatchLists) {
      return
    }
    allWatchLists.forEach(watchlist => {
      webSocket.emitSubscribeWatchlist(watchlist.id, handleWatchlistMessage)
    })
  }

  const onWebsocketOpen = (newValue: string): void => {
    if (newValue === WebSocketAuthStatus.AUTHENTICATED) {
      emitToWebsocket()
    }
  }
  watch(authStatus, onWebsocketOpen)
  onWebsocketOpen(authStatus.value)

  function handleWatchlistMessage (data: unknown, error: unknown): void {
    // TODO implement
    // console.log('handleWatchlistMessage', data, error)
  }

  async function loadData (): Promise<void> {
    await loadWatchLists(async () => {
      return await fetchWatchlistData()
    })

    if (authStatus.value) {
      emitWatchlistsToWebsocket()
    }

    isInitialized.value = true
  }

  async function fetchWatchlistData (): Promise<APIWatchlistResponse[]> {
    const response = await api.get(
      '/v6/watchlists',
      {
        headers: { Accept: 'application/json, text/plain, */*' },
        cancellationPolicy: CancellationPolicy.OnLogout
      }
    )

    const watchlists: WatchlistsInventoryInner[] = response.data
    const watchlistsContent : APIWatchlistResponse[] = []
    if (!watchlists.length) {
      const newWatchlistId = await createDefaultWatchlist()
      watchlists.push({ id: newWatchlistId })
    }
    for (const watchlist of watchlists) {
      if (!watchlist.id) {
        continue
      }
      const watchlistContent = await fetchWatchlist(watchlist.id)

      watchlistsContent.push(Object.assign({}, watchlistContent, { id: watchlist.id }) as APIWatchlistResponse)
    }

    return watchlistsContent
  }

  async function createDefaultWatchlist (): Promise<number> {
    const body = { name: 'watchlist' }
    const response = await api.post(
      '/v6/watchlists',
      body,
      { cancellationPolicy: CancellationPolicy.OnLogout }
    )
    return response.data
  }

  async function fetchWatchlist (watchlistId: number): Promise<WatchlistContentData> {
    const response = await api.get(
      `/v6/watchlists/${watchlistId}`,
      { cancellationPolicy: CancellationPolicy.OnLogout }
    )

    return response.data as WatchlistContentData
  }

  async function removeViewFromWatchlist (watchlistId: number,
    viewNames: string[], updateWatchlistState = true): Promise<void> {
    const newWatchListContent = await api.delete(
      `/v6/watchlists/${watchlistId}/filters`,
      {
        data: viewNames,
        cancellationPolicy: CancellationPolicy.OnLogout
      }
    )
    if (updateWatchlistState) {
      updateWatchlistFromAPIResponse(watchlistId, newWatchListContent.data)
    }
  }

  async function updateView (view : WatchlistView, oldViewName?: string): Promise<void> {
    if (oldViewName) {
      // Delete old View
      await removeViewFromWatchlist(view.watchlistId, [oldViewName], false)
      // Add 'new' View
      const newView = {
        name: view.name,
        filter: Auxiliary.getFilterSettingsListByTitleFilter(view.filter, true),
        alerts: view.alerts
      }
      await addItemToWatchlist(view.watchlistId, { filters: [newView] })
    } else {
      const filterData = {
        filter: Auxiliary.getFilterSettingsListByTitleFilter(view.filter, true),
        alerts: view.alerts
      }
      const response = await api.post(
        `/v6/watchlists/${view.watchlistId}/filters`,
        filterData,
        { cancellationPolicy: CancellationPolicy.OnLogout }
      )
      if (response.status !== 200) {
        return
      }
      updateWatchlistView(view)
    }
  }

  function updateWatchlistFromAPIResponse (watchlistId: number, watchlistToUpdate: WatchlistContentData): void {
    const watchlistData = Object.assign({}, watchlistToUpdate, { id: watchlistId }) as APIWatchlistResponse
    if (watchLists.value.value === undefined) {
      watchLists.value.value = [watchlistData]
      return
    }
    const index = watchLists.value.value?.findIndex(watchlist => watchlist.id === watchlistId)
    if (index !== -1) {
      watchLists.value.value[index] = watchlistData
    } else {
      watchLists.value.value.push(watchlistData)
    }
  }

  function updateWatchlistView (watchlistView : WatchlistView): void {
    if (watchLists.value.value === undefined) {
      return
    }
    const index = watchLists.value.value?.findIndex(watchlist => watchlist.id === watchlistView.watchlistId)
    if (index === undefined || index < 0) {
      return
    }
    const watchList = watchLists.value.value[index]
    const filterIndex = watchList.filters?.findIndex(filter => filter.name === watchlistView.name)
    if (!filterIndex || filterIndex < 0) {
      return
    }

    const filters = watchLists.value.value[index].filters
    if (!filters || !filters[filterIndex]) {
      return
    }
    filters[filterIndex] = {
      name: watchlistView.name,
      filter: Auxiliary.getFilterSettingsListByTitleFilter(watchlistView.filter),
      alerts: watchlistView.alerts
    }
  }

  async function addItemToWatchlist (watchlistId: number, watchlistItemsToAdd: WatchlistItemToAdd): Promise<void> {
    const newWatchListContent = await watchlistsApiFactory.addItemWatchlist(watchlistId, watchlistItemsToAdd)

    updateWatchlistFromAPIResponse(watchlistId, newWatchListContent.data)
  }

  async function removeAuthorFromWatchlist (watchlistId: number, name: string, source: string): Promise<void> {
    const newWatchListContent = await watchlistsApiFactory.removeAuthorsFromWatchlist(
      watchlistId,
      { authors: [{ name, source }] }
    )
    updateWatchlistFromAPIResponse(watchlistId, newWatchListContent.data)
  }

  function findViewByViewFilter (titleFilter: ViewFilter): WatchlistView | undefined {
    return views.value.find(view => Auxiliary.compareObjects(titleFilter, view.filter) === 0)
  }

  function getTitleFromView (view: WatchlistView) : InventoryTitle|undefined {
    if (view.type !== ViewType.ASSET) {
      return undefined
    }
    const titleId = view.filter.asset[0]
    if (!titleId) {
      return undefined
    }
    return commonStore.getTitleById(titleId)
  }

  function getViewEntitiesByViewFilter (viewFilter : ViewFilter) : ViewFilterEntities {
    const countryEntities = viewFilter.country
      .map(un => commonStore.getCountryByUn(un))
      .filter(country => country !== undefined) as CountriesInner []
    const exchangeEntities = viewFilter.exchange
      .map(exchangeId => commonStore.getExchangeById(exchangeId))
      .filter(exchange => exchange !== undefined) as ExchangeData []
    const sectorEntities = viewFilter.sector
      .map(sectorId => commonStore.getSectorById(sectorId))
      .filter(sector => sector !== undefined) as InventorySector []
    const listEntities = viewFilter.list
      .map(listId => commonStore.getListById(listId))
      .filter(list => list !== undefined) as ListsInner []
    const keyEventEntities = viewFilter.keyEvent
      .map(keyEventId => commonStore.getKeyEventById(keyEventId))
      .filter(keyEvent => keyEvent !== undefined) as KeyEvent []
    const entityTypes = viewFilter.type
      .map(typeValue => commonStore.getEntityTypeByValue(typeValue))
      .filter(type => type !== undefined) as AssetEntityType[]
    const assetEntities = viewFilter.asset
      .map(assetId => commonStore.getTitleById(assetId))
      .filter(asset => asset !== undefined) as InventoryTitle[]
    return {
      country: countryEntities,
      exchange: exchangeEntities,
      sector: sectorEntities,
      list: listEntities,
      keyEvent: keyEventEntities,
      type: entityTypes,
      asset: assetEntities
    }
  }

  return {
    isInitialized,
    watchLists,
    views,
    authors,
    updateView,
    addItemToWatchlist,
    removeAuthorFromWatchlist,
    removeViewFromWatchlist,
    findViewByViewFilter,
    getTitleFromView,
    getViewEntitiesByViewFilter
  }
})

export type WatchlistStoreType = ReturnType<typeof useWatchlistStore>;
