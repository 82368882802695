import { capitalize, computed, Ref, ref, watch } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { WebSocketAuthStatus, webSocketStore } from 'stores/web-socket-store'
import { api, fetchInfosForTitles, FilterSettingsList, filterTitles, ViewFilter } from 'boot/axios'

import { AsyncData } from 'components/models'
import { useAuthUserStore } from 'stores/auth-user-store'
import { CountriesInner, EntityType, ExchangesInner, ListsInner, TitleInformation } from '@stockpulse/typescript-axios'
import { ROUTE_NAME_ASSET, ROUTE_NAME_HOME, ROUTE_NAME_VIEW, routeRequiresAuthorization } from 'src/router/routes'
import { useRouter } from 'vue-router'
import NotificationService from 'src/services/NotificationService'
import { useI18n } from 'vue-i18n'
import { filterV3FieldNames } from 'stores/watchlist-store'
import { TitleInventoryInner } from '@stockpulse/typescript-axios/types/title-inventory-inner'
import { SubscriptionId, useRealTimeDataStore } from 'stores/realtime-data-store'
import { Auxiliary } from 'src/helper/Auxiliary'
import { DEFAULT_REPORT_MODELS } from 'src/helper/ReportHelper'
import * as Sentry from '@sentry/vue'
import { CaptureContext } from '@sentry/types'
import { KeyEventsInner } from '@stockpulse/typescript-axios/dist/types/key-events-inner'
import { StandardTriggersInner } from '@stockpulse/typescript-axios/types/standard-triggers-inner'
import { useAsyncApiData } from 'src/composables/useAsyncApiData'
import { CancellationPolicy } from 'src/services/AxiosRequestManager'

export interface Signal {
  b: number,
  id: number,
  r_type: string,
  s: number,
  s_id: number,
  s_n: string,
  t: number,
  t_id: number,
  title_name: string
}

export interface InventoryTitle {
  n: string,
  type: string,
  id: number,
  icon: string
}

export interface StreamEvent {
  titleId: number,
  time: number,
  label: string,
  buzz: number,
  sentimentScore: number,
  sentimentIcon: string,
  sentimentIconColor: string,
  className: string,
  icon?: string
}

export interface TitleInfoCollection {
    [key: number]: AsyncData<TitleInformation>
}

interface UserFacingLoadingState {
  label: string,
  loading: boolean
}

export type ExtendedCountriesInner = CountriesInner & {icon?: string}

export interface InventorySector {name: string, type: string, id: number, icon: string}

export type ExchangeData = ExchangesInner & { icon?: string }

export interface KeyEvent {id:number, name:string, icon:string}

export interface Source {source:string, lang:string, type:string, source_type:string, icon?:string}

export interface TrendingKeyEvents {[key:number]:number}

export interface AssetEntityType {
  label: string,
  value: string
}

export interface MessageSourceType {
  type: string,
  class: string,
  icon: string|null
}

export interface MorningBriefingEdition {
  id: string
  edition: string
  icon?: string,
  category: string
}

export interface UiPreferences {
  markets:string[],
  titleTypes:string[]
}

export interface Markets {
  US: boolean,
  CA: boolean,
  CN: boolean,
  IN: boolean,
  EU: boolean,
  REST_AS: boolean
}

export type MarketTypes = keyof Markets;

export interface TitleTypes {
  company: boolean,
  index: boolean,
  forex: boolean,
  crypto_currency: boolean,
  raw_material: boolean,
  fund: boolean
}

export interface UiPreferencesGUI {
  markets: Markets
  titleTypes: TitleTypes,
  countryUnCodes: string[]
}

export interface UserOpts {
  uiPreferences?:UiPreferences
}

const SIGNAL_GROUP_IDENTIFIER = 'signal'

const SUSPICIOUS_LOADING_TIMEOUT_MS = 15_000

export const useCommonStore = defineStore('commonStore', () => {
  // "You must call useI18n at top of the setup"
  const { t } = useI18n()
  const router = useRouter()
  const notificationService = new NotificationService(router)
  const webSocket = webSocketStore()
  const authUserStore = useAuthUserStore()
  const realtimeDataStore = useRealTimeDataStore()
  const { authStatus } = storeToRefs(webSocket)
  const { hasValidSubscription } = storeToRefs(authUserStore)

  const isInitialized = ref(false)
  const hasError = ref(false)
  const hasSuspiciousLoadingTime = ref(false)
  const loadingTimeoutId : Ref<undefined|number> = ref()

  const keyEvents: Ref<AsyncData<{[key: number]: KeyEvent}>> = ref({ loading: true, value: {} })
  const titles: Ref<AsyncData<{[key: number]: InventoryTitle}>> = ref({ loading: true, value: {} })
  const sectors: Ref<AsyncData<{[key: number]:InventorySector}>> = ref({ loading: true, value: {} })
  const countries: Ref<AsyncData<ExtendedCountriesInner[]>> = ref({ loading: true, value: [] })
  const exchanges: Ref<AsyncData<ExchangeData[]>> = ref({ loading: true, value: [] })
  const lists: Ref<AsyncData<ListsInner[]>> = ref({ loading: true, value: [] })
  const sources: Ref<AsyncData<Source[]>> = ref({ loading: true, value: [] })
  const messageSourceTypes: Ref<AsyncData<MessageSourceType[]>> = ref({ loading: true, value: [] })

  const {
    state: preIPOCompanies,
    load: loadPreIPOCompanies
  } = useAsyncApiData<TitleInformation[]>(undefined, [])

  const {
    state: topBuzzTitleIds,
    load: loadTopBuzzTitleIds
  } = useAsyncApiData<number[]>(undefined, [])

  const {
    state: topBuzzIndexIds,
    load: loadTopBuzzIndexIds
  } = useAsyncApiData<number[]>(undefined, [])

  const {
    state: trendingKeyEvents,
    load: loadTrendingKeyEvents
  } = useAsyncApiData<{[key: number]: TrendingKeyEvents}>(undefined, {})

  const {
    state: morningBriefingEditions,
    load: loadMorningBriefingEditions
  } = useAsyncApiData<MorningBriefingEdition[]>(undefined, [])

  const {
    state: userOpts,
    load: loadUserOpts
  } = useAsyncApiData<UserOpts>(undefined, {})

  const eventStream: Ref<StreamEvent[]> = ref([])

  const entityTypes: Ref<AssetEntityType[]> = ref([
    { label: 'Company', value: EntityType.Company },
    { label: 'Crypto Currency', value: EntityType.CryptoCurrency },
    { label: 'Derivative', value: EntityType.Derivative },
    { label: 'Forex', value: EntityType.Forex },
    { label: 'Index', value: EntityType.Index },
    { label: 'Raw Material', value: EntityType.RawMaterial },
    { label: 'Fund', value: EntityType.Fund }
  ])

  const subscriptionIds: Ref<SubscriptionId[]> = ref([])

  const titlesInfo: Ref<TitleInfoCollection> = ref({})

  // trigger login check immediate, to open socket after auth store hydration
  watch(() => [router.currentRoute.value.name, hasValidSubscription], () => {
    if (isInitialized.value ||
      !routeRequiresAuthorization(String(router.currentRoute.value.name)) ||
      !hasValidSubscription) {
      return
    }
    loadData()
  }, { immediate: true })

  function emitToWebsocket (): void {
    // Subscribe to StdSignals
    webSocket.emitSubscribeStdSignals(handleStdSignalMessage)
    webSocket.emitSubscribeSignals(handleSignalMessage)
  }

  const onWebsocketOpen = (newValue: string): void => {
    if (newValue === WebSocketAuthStatus.AUTHENTICATED) {
      emitToWebsocket()
    }
  }
  watch(authStatus, onWebsocketOpen)
  onWebsocketOpen(authStatus.value)

  function handleStdSignalMessage (data: unknown, error: unknown): void {
    if (error) {
      return
    }
    const signalData = data as Signal

    // Check for duplicate
    if (eventStream.value.find(ev => ev.time === signalData.t && ev.titleId === signalData.id)) {
      return
    }
    notificationService.notify({
      message: signalData.s_n,
      caption: signalData.title_name,
      group: SIGNAL_GROUP_IDENTIFIER,
      actions: [
        {
          label: 'Show',
          handler: (): void => {
            router.push({ name: ROUTE_NAME_ASSET, query: { title_id: signalData.id } })
          }
        }
      ],
      position: 'bottom-right',
      icon: 'spi-bell'
    }, [ROUTE_NAME_ASSET, ROUTE_NAME_VIEW, ROUTE_NAME_HOME])

    const className = 'bg-neutral-tint-90 '
    const sentimentScore = Auxiliary.calculateSentimentScore(signalData.s || 0) / 100
    const sentimentIcon = Auxiliary.getIconClassBySentimentValue(sentimentScore)
    const sentimentIconColor = Auxiliary.getColorForSentimentScore(sentimentScore)
    addEvent({
      time: signalData.t,
      label: signalData.s_n,
      titleId: signalData.id,
      className,
      buzz: signalData.b,
      sentimentScore,
      sentimentIcon,
      sentimentIconColor
    })
  }

  function handleSignalMessage (data: unknown, error: unknown): void {
    if (error) {
      return
    }
    const signalData = data as Signal

    // Check for duplicate
    if (eventStream.value.find(ev => ev.time === signalData.t && ev.titleId === signalData.id)) {
      return
    }

    notificationService.notify({
      message: signalData.s_n,
      caption: signalData.title_name,
      group: SIGNAL_GROUP_IDENTIFIER,
      actions: [
        {
          label: 'Show',
          handler: (): void => {
            router.push({ name: ROUTE_NAME_ASSET, query: { title_id: signalData.id } })
          }
        }
      ],
      position: 'bottom-right',
      icon: 'spi-bell'
    })

    let className = 'bg-neutral-tint-90'
    let icon
    if (signalData.s_n.startsWith('A.I. Alert')) {
      className = 'bg-yellow-tint-70'
      icon = 'spi-brain-circuit'
    } else {
      icon = 'spi-binoculars'
    }

    const sentimentScore = Auxiliary.calculateSentimentScore(signalData.s || 0) / 100
    const sentimentIcon = Auxiliary.getIconClassBySentimentValue(sentimentScore)
    const sentimentIconColor = Auxiliary.getColorForSentimentScore(sentimentScore)
    addEvent({
      time: signalData.t,
      label: signalData.s_n,
      titleId: signalData.id,
      className,
      buzz: signalData.b,
      sentimentScore,
      sentimentIcon,
      sentimentIconColor,
      icon
    })
  }

  function addEvent (event : StreamEvent): void {
    if (eventStream.value.length > 100) {
      eventStream.value.pop()
    }
    eventStream.value.unshift(event)
  }

  const userFacingLoadingStates = computed<UserFacingLoadingState[]>(() => {
    return [
      { label: t('InitialLoadingComponent.assetsLabel'), loading: titles.value.loading },
      { label: t('InitialLoadingComponent.exchangesLabel'), loading: exchanges.value.loading && lists.value.loading },
      { label: t('InitialLoadingComponent.countriesLabel'), loading: countries.value.loading },
      { label: t('InitialLoadingComponent.sourcesLabel'), loading: sources.value.loading },
      { label: t('InitialLoadingComponent.keyEventsLabel'), loading: keyEvents.value.loading },
      {
        label: t('InitialLoadingComponent.trendingAssetsLabel'),
        loading: topBuzzTitleIds.value.loading && topBuzzIndexIds.value.loading &&
          preIPOCompanies.value.loading && trendingKeyEvents.value.loading
      }
    ]
  })

  const uiPreferencesGUI = computed<UiPreferencesGUI>(() => {
    const euCountries = countries.value.value
      ?.filter(country => country.continent_code === 'EU')
      ?.map(country => country.iso) ??
      []
    const restOfAsia = countries.value.value
      ?.filter(country => country.continent_code === 'AS' && !['CN', 'IN'].includes(country.iso ?? ''))
      ?.map(country => country.iso) ??
      []

    const countryUnCodes = userOpts.value.value?.uiPreferences?.markets?.map(countryIso => {
      return getCountryByIso(countryIso)?.un
    }).filter(countryIso => countryIso !== undefined) as string[]

    return {
      markets: {
        US: userOpts.value.value?.uiPreferences?.markets?.includes('US') ?? false,
        CA: userOpts.value.value?.uiPreferences?.markets?.includes('CA') ?? false,
        CN: userOpts.value.value?.uiPreferences?.markets?.includes('CN') ?? false,
        IN: userOpts.value.value?.uiPreferences?.markets?.includes('IN') ?? false,
        EU: euCountries.every(euCountry =>
          userOpts.value.value?.uiPreferences?.markets?.includes(euCountry ?? '')) ?? false,
        REST_AS: restOfAsia.every(asCountry =>
          userOpts.value.value?.uiPreferences?.markets?.includes(asCountry ?? '')) ?? false
      },
      titleTypes: {
        company: userOpts.value.value?.uiPreferences?.titleTypes?.includes('company') ?? false,
        forex: userOpts.value.value?.uiPreferences?.titleTypes?.includes('forex') ?? false,
        index: userOpts.value.value?.uiPreferences?.titleTypes?.includes('index') ?? false,
        crypto_currency: userOpts.value.value?.uiPreferences?.titleTypes?.includes('crypto_currency') ?? false,
        raw_material: userOpts.value.value?.uiPreferences?.titleTypes?.includes('raw_material') ?? false,
        fund: userOpts.value.value?.uiPreferences?.titleTypes?.includes('fund') ?? false
      },
      countryUnCodes: countryUnCodes ?? []
    }
  })

  const transformGUIPreferencesToUiPreferences = (preferences : UiPreferencesGUI) :UiPreferences => {
    const markets : string[] = []

    const euCountries: string[] = countries.value.value
      ?.filter(country => country.continent_code === 'EU' && country.iso)
      ?.map(country => country.iso ?? '') ??
      []
    const restOfAsia : string[] = countries.value.value
      ?.filter(country => country.continent_code === 'AS' && country.iso && !['CN', 'IN'].includes(country.iso ?? ''))
      ?.map(country => country.iso ?? '') ??
      []

    Object.keys(preferences.markets).forEach(mapKey => {
      if (!preferences.markets[mapKey as keyof Markets]) {
        return
      }
      switch (mapKey) {
      case 'EU':
        markets.push(...euCountries)
        break
      case 'REST_AS':
        markets.push(...restOfAsia)
        break
      default:
        markets.push(mapKey)
      }
    })

    const titleTypes: string[] = []
    Object.keys(preferences.titleTypes).forEach(titleType => {
      if (!preferences.titleTypes[titleType as keyof TitleTypes]) {
        return
      }
      titleTypes.push(titleType)
    })

    return {
      markets,
      titleTypes
    }
  }

  async function loadData (): Promise<void> {
    keyEvents.value.loading = true
    titles.value.loading = true
    exchanges.value.loading = true
    countries.value.loading = true
    preIPOCompanies.value.loading = true
    lists.value.loading = true
    sources.value.loading = true
    topBuzzTitleIds.value.loading = true
    topBuzzIndexIds.value.loading = true
    trendingKeyEvents.value.loading = true
    isInitialized.value = false
    hasError.value = false

    if (subscriptionIds.value.length > 0) {
      realtimeDataStore.unsubscribeTitleUpdates(subscriptionIds.value)
    }

    if (loadingTimeoutId.value) {
      window.clearTimeout(loadingTimeoutId.value)
    }
    loadingTimeoutId.value = window.setTimeout(() => {
      hasSuspiciousLoadingTime.value = true
    }, SUSPICIOUS_LOADING_TIMEOUT_MS)

    try {
      const initialRequests : Promise<void>[] = [
        loadTitles(),
        loadUserOptsInternal(),
        loadSectors(),
        loadCountries()
      ]
      await Promise.all(initialRequests)

      const requests : Promise<void>[] = [
        loadKeyEvents(),
        loadLists(),
        loadExchanges(),
        loadSources(),
        loadMessageSourceTypes(),
        loadLatestEvents(),
        // TODO do not wait for non-critical data, refactor into another store
        loadPreIPOInternal(),
        loadTopBuzzTitleIdsInternal(),
        loadTopBuzzIndexIdsInternal(),
        loadTrendingKeyEventsInternal(),
        loadMorningBriefingEditionsInternal()
      ]

      await Promise.all(requests)

      hasError.value = false
      isInitialized.value = true
      hasSuspiciousLoadingTime.value = false
    } catch (error) {
      const context : CaptureContext = {
        user: {
          subscription_type: authUserStore.user.subscription_type,
          username: authUserStore.user.username
        },
        level: 'fatal'
      }
      Sentry.captureException(error, context)
      hasError.value = true
      isInitialized.value = false
    } finally {
      window.clearTimeout(loadingTimeoutId.value)
    }
  }

  async function loadKeyEvents (): Promise<void> {
    const fetchedKeyEvents = await fetchKeyEvents()
    keyEvents.value = {
      loading: false,
      value: fetchedKeyEvents
    }
  }

  async function loadLatestEvents (): Promise<void> {
    const response = await api.get('/v6/standard_triggers?rule_types=bsp_patterns,ebs_patterns&limit=200')
    const lastEvents = response.data as StandardTriggersInner[]
    const ids = lastEvents.map(eventData => eventData.id)
    const filteredIds : number[] = ids.filter(id => id !== undefined) as number[]
    const distinctIds = [...new Set(filteredIds)]
    const titleInfos = await fetchInfosForTitles(distinctIds)

    const maxEventsToLoad = 20
    let addedEvents = 0
    lastEvents.forEach(lastEvent => {
      if (addedEvents >= maxEventsToLoad) {
        return
      }
      if (!lastEvent.s_n || !['Moving Stock Alert', 'Pre-Earnings Stock Alert'].includes(lastEvent.s_n) ||
          !lastEvent.t || !lastEvent.id || !lastEvent.b
      ) {
        return
      }

      // Filter Events by preferences
      const titleInfo = titleInfos.find(title => title.id === lastEvent.id)
      if (!titleInfo || (uiPreferencesGUI.value.countryUnCodes.length &&
        !uiPreferencesGUI.value.countryUnCodes.includes(titleInfo?.country ?? ''))) {
        return
      }

      const className = 'bg-neutral-tint-90 '
      const sentimentScore = Auxiliary.calculateSentimentScore(lastEvent.s || 0) / 100
      const sentimentIcon = Auxiliary.getIconClassBySentimentValue(sentimentScore)
      const sentimentIconColor = Auxiliary.getColorForSentimentScore(sentimentScore)
      addEvent({
        time: lastEvent.t,
        label: lastEvent.s_n,
        titleId: lastEvent.id,
        className,
        buzz: lastEvent.b,
        sentimentScore,
        sentimentIcon,
        sentimentIconColor
      })
      addedEvents++
    })
  }

  async function fetchKeyEvents (): Promise<{[key: number]: KeyEvent}> {
    const response = await api.get(
      '/v6/key_events_v2?&add_icon=true',
      { cancellationPolicy: CancellationPolicy.OnLogout }
    )
    const fetchedKeyEvents: Array<{ key_event_id: number; name: string, icon: string }> = response.data.reduce(
      (carry: Array<{ key_event_id: number, name: string, icon: string }>,
        a: { key_events: Array<{ key_event_id: number, name: string, icon: string }> }
      ) =>
        carry.concat(a.key_events), [] as Array<{ key_event_id: number, name: string, icon: string }>
    )
    const keyEventCollection : {[key: number]: KeyEvent} = {}
    fetchedKeyEvents.forEach(keyEvent => {
      keyEventCollection[keyEvent.key_event_id] = {
        name: keyEvent.name,
        icon: keyEvent.icon,
        id: keyEvent.key_event_id
      }
    })
    return keyEventCollection
  }

  async function loadTrendingKeyEventsInternal (): Promise<void> {
    return loadTrendingKeyEvents(async () => {
      const allSectors = sectors.value.value ?? {}
      const filteredSectorIds = Object.keys(allSectors).map(Number).filter(sectorId => {
        const sector = allSectors[sectorId]
        return sector?.type === 'sector'
      })
      const randomIndex = Math.floor(Math.random() * filteredSectorIds.length)
      const sectorId = filteredSectorIds[randomIndex]
      // Fetch Key Events for random sector
      const fetchedKeyEvents = await fetchTrendingSectorKeyEvents(sectorId)

      return {
        ...trendingKeyEvents.value.value,
        [sectorId]: fetchedKeyEvents
      }
    })
  }

  async function loadMorningBriefingEditionsInternal (): Promise<void> {
    return loadMorningBriefingEditions(async () => {
      const response = await api.get('/v6/morning-briefings/editions')
      return response.data as MorningBriefingEdition[]
    })
  }

  async function loadUserOptsInternal (): Promise<void> {
    return loadUserOpts(async () => {
      const response = await api.get('/v6/userdata/opts')
      return response.data as UserOpts
    })
  }

  async function fetchTrendingSectorKeyEvents (sectorId: number) :Promise<TrendingKeyEvents> {
    const response = await api.get(
      `/v6/sectors/${sectorId}/key_events_v2`,
      { cancellationPolicy: CancellationPolicy.OnLogout }
    )
    const data = response.data as KeyEventsInner[]
    const trendingSectorKeyEventsObject : TrendingKeyEvents = {}
    data.forEach(keyEventDs => {
      if (keyEventDs.key_event_id === undefined) {
        return
      }
      trendingSectorKeyEventsObject[keyEventDs.key_event_id] = keyEventDs.sum_total ?? 0
    })
    return trendingSectorKeyEventsObject
  }

  async function loadExchanges (): Promise<void> {
    const fetchedExchanges = await api.get('/v6/exchanges?&add_icon=true')
    exchanges.value = {
      loading: false,
      value: fetchedExchanges.data
    }
  }

  async function loadCountries (): Promise<void> {
    const fetchedCountries = await api.get('/v6/countries?&add_icon=true')
    countries.value = {
      loading: false,
      value: fetchedCountries.data
    }
  }
  async function loadSources (): Promise<void> {
    const fetchedSources = await api.get('/v6/sources?add_icon=true')
    sources.value = {
      loading: false,
      value: fetchedSources.data
    }
  }

  async function loadMessageSourceTypes (): Promise<void> {
    const fetchedMessageSourceTypes = await api.get('/v6/source_types?add_icon=true')
    messageSourceTypes.value = {
      loading: false,
      value: fetchedMessageSourceTypes.data
    }
  }

  async function loadTopBuzzTitleIdsInternal (): Promise<void> {
    return loadTopBuzzTitleIds(async () => {
      const filters: FilterSettingsList = []

      // Filter by referenced countries
      if (uiPreferencesGUI.value.countryUnCodes.length) {
        filters.push({ field: filterV3FieldNames.country, comparator: 'eq', value: uiPreferencesGUI.value.countryUnCodes })
      }

      const fetchedBuzzTitlesResponse = await api.post(
        '/v6/titles?limit=5&sort_by=buzz&partition=10minute&only_top_titles=1',
        filters
      )
      const fetchedBuzzTitles = fetchedBuzzTitlesResponse.data as { id: number }[]
      const titleIds: number[] = fetchedBuzzTitles
        .filter(buzzTitle => buzzTitle.id !== undefined)
        .map(buzzTitle => buzzTitle.id)

      subscriptionIds.value.push(realtimeDataStore.subscribeTitleUpdates(titleIds))

      return titleIds
    })
  }

  async function loadTopBuzzIndexIdsInternal (): Promise<void> {
    return loadTopBuzzIndexIds(async () => {
      const filters: FilterSettingsList = [{ field: filterV3FieldNames.type, comparator: 'eq', value: 'index' }]

      // Filter by referenced countries
      if (uiPreferencesGUI.value.countryUnCodes.length) {
        filters.push({ field: filterV3FieldNames.country, comparator: 'eq', value: uiPreferencesGUI.value.countryUnCodes })
      }
      const fetchedBuzzIndexesResponse = await api.post(
        '/v6/titles?limit=5&sort_by=buzz&partition=10minute',
        filters
      )

      const fetchedBuzzIndexes = fetchedBuzzIndexesResponse.data as TitleInventoryInner[]
      const indexIds: number[] = fetchedBuzzIndexes
        .filter(buzzTitle => buzzTitle.id !== undefined)
        .map(buzzTitle => buzzTitle.id) as number[]

      subscriptionIds.value.push(realtimeDataStore.subscribeTitleUpdates(indexIds))

      return indexIds
    })
  }

  async function loadTitles (): Promise<void> {
    const fetchedTitles = await fetchTitles()
    const titlesObject : {[key: number]: InventoryTitle} = {}
    fetchedTitles.forEach(title => {
      titlesObject[title.id] = title
    })

    titles.value = {
      loading: false,
      value: titlesObject
    }
  }

  async function fetchTitles (): Promise<InventoryTitle[]> {
    const response = await api.get('/v6/inventory?add_icon=true')
    return response.data
  }

  async function loadPreIPOInternal (): Promise<void> {
    return loadPreIPOCompanies(async () => {
      const fetchedPreIPO = await fetchPreIPO()
      if (fetchedPreIPO.length > 0) {
        subscriptionIds.value.push(realtimeDataStore.subscribeTitleUpdates(fetchedPreIPO.map(preIpo => preIpo.id)))
      }
      return fetchedPreIPO
    })
  }

  async function fetchPreIPO (): Promise<TitleInformation[]> {
    const filterList : FilterSettingsList = []
    filterList.push({ field: 'type', comparator: 'eq', value: 'pre_ipo' })
    filterList.push({ field: filterV3FieldNames.country, comparator: 'gte', value: '' })

    const topTitles = await filterTitles(filterList, 100, 'buzz', DEFAULT_REPORT_MODELS.realtime.name)
    const titleIds = topTitles.map((title) => title.id)
    return await fetchInfosForTitles(titleIds)
  }

  async function loadSectors (): Promise<void> {
    const fetchedSectors = await fetchSectors()
    const sectorsObject : {[key: number]: InventorySector} = {}
    fetchedSectors.forEach(sector => {
      sectorsObject[sector.id] = sector
    })
    sectors.value = {
      loading: false,
      value: sectorsObject
    }
  }

  async function fetchSectors (): Promise<InventorySector[]> {
    const response = await api.get('/v6/sectors?add_icon=true')
    return response.data
  }

  async function loadLists (): Promise<void> {
    const fetchedLists = await fetchLists()
    lists.value = {
      loading: false,
      value: fetchedLists
    }
  }

  async function fetchLists (): Promise<ListsInner[]> {
    const response = await api.get('/v6/lists?add_icon=true')
    return response.data
  }

  function translateKeyEvent (keyEventId: number): string {
    const keyEventName = keyEvents.value.value ? keyEvents.value.value[keyEventId]?.name : undefined

    if (!keyEventName) {
      return ''
    }
    const translationKey = 'MessageStatistics.keyEvent.' + keyEventName
    const translation = t(translationKey)
    return translation !== translationKey ? translation : keyEventName
  }
  function getKeyEventIconById (eventId: number): string|undefined {
    return keyEvents.value.value ? keyEvents.value.value[eventId]?.icon : undefined
  }
  function getKeyEventById (eventId: number): KeyEvent|undefined {
    return keyEvents.value.value ? keyEvents.value.value[eventId] : undefined
  }
  function getSectorIconById (sectorId: number): string|undefined {
    return sectors.value.value ? sectors.value.value[sectorId]?.icon : undefined
  }
  function getSectorNameById (sectorId: number): string|undefined {
    return sectors.value.value ? sectors.value.value[sectorId]?.name : undefined
  }
  function getSectorById (sectorId: number): InventorySector|undefined {
    return sectors.value.value ? sectors.value.value[sectorId] : undefined
  }

  function getListById (listId: number): ListsInner|undefined {
    return lists.value.value?.find(list => list.id === listId)
  }

  function getTitleNameById (titleId: number): string {
    return titles.value.value?.[titleId]?.n || ''
  }
  function getTitleById (titleId: number): InventoryTitle | undefined {
    return titles.value.value?.[titleId]
  }
  function getTitleIconById (titleId: number): string | undefined {
    return titles.value.value?.[titleId]?.icon
  }

  function getTitlesById (titleIds: number[]) : InventoryTitle[] {
    return titleIds.reduce((accumulatedTitles, titleId) => {
      const titleInfo = titles.value.value?.[titleId]
      if (titleInfo) {
        accumulatedTitles.push(titleInfo)
      }
      return accumulatedTitles
    }, [] as InventoryTitle[])
  }

  function getExchangeByName (exchangeName:string): ExchangeData |undefined {
    return exchanges.value.value?.find(exchange => exchange.name?.toUpperCase() === exchangeName.toUpperCase())
  }

  function getExchangeById (id: number): ExchangeData | undefined {
    return exchanges.value.value?.find(exchange => exchange.exchange_id === id)
  }

  function getSourceByName (name: string): Source | undefined {
    return sources.value.value?.find(source => source.source === name)
  }
  function getSourceIconByName (name: string, fallbackIcon = 'spi-globe'): string {
    return sources.value.value?.find(source => source.source === name)?.icon || fallbackIcon
  }

  function getMessageSourceTypeIconByName (name: string, fallbackIcon = 'spi-message-lines'): string {
    return messageSourceTypes.value.value?.find(sourceType => sourceType.type === name)?.icon || fallbackIcon
  }

  function getCountryByName (countryName: string): ExtendedCountriesInner | undefined {
    return countries.value.value?.find(country => country.name === countryName)
  }

  function getCountryByUn (un: string): ExtendedCountriesInner | undefined {
    return countries.value.value?.find(country => country.un === un)
  }

  function getCountryByIso (iso: string): ExtendedCountriesInner | undefined {
    return countries.value.value?.find(country => country.iso === iso)
  }

  function getCountryIconByUn (un: string): string | undefined {
    const iso = countries.value.value?.find(country => country.un === un)?.iso
    if (!iso) {
      return 'spi-globe'
    }
    return 'spf-flag-' + iso.toLowerCase()
  }

  function getEntityTypeByValue (value: string) : AssetEntityType|undefined {
    return entityTypes.value.find(entityType => entityType.value === value)
  }

  function createNameFromTitleFilter (titleFilter: ViewFilter): string {
    const elements: (string | undefined)[] = []
    elements.push(...titleFilter.country.map((x: string) => getCountryByUn(x)?.name))
    elements.push(...titleFilter.exchange.map((x: number) => getExchangeById(x)?.name))
    // TODO regions
    // elements.push(...titleFilter.region.map((x: string) => x));
    elements.push(...titleFilter.sector.map((x: number) => getSectorById(x)?.name))
    elements.push(...titleFilter.list.map((x: number) => getListById(x)?.n))
    elements.push(...titleFilter.asset.map((x: number) => getTitleById(x)?.n))
    elements.push(...titleFilter.keyEvent.map((x: number) => getKeyEventById(x)?.name))
    elements.push(...titleFilter.type.map((x: string) =>
      x.replace('_', ' ')
        .split(' ')
        .map(word => capitalize(word))
        .join(' ')))
    const titleFilterName = elements.filter(x => x !== undefined).join(', ')
    return titleFilterName.length > 0 ? titleFilterName : t('TitleFilter.allAssets')
  }

  async function loadTitleInfo (titleId: number): Promise<void> {
    await loadTitleInfos([titleId])
  }

  async function loadTitleInfos (titleIds: number[]): Promise<void> {
    if (titleIds.every(titleId => titlesInfo.value[titleId]?.value !== undefined)) {
      return
    }

    for (const titleId of titleIds) {
      titlesInfo.value[titleId] = { loading: true }
    }

    const titleInfos = await fetchInfosForTitles(titleIds)

    // Iterate over titleIds to set loading = false on missing title infos as well
    const newTitleInfos: TitleInfoCollection = {}
    for (const titleId of titleIds) {
      const titleInfo = titleInfos.find(titleInfo => titleInfo.id === titleId)

      if (titleInfo === undefined) {
        newTitleInfos[titleId] = { loading: false }
        continue
      }

      if (titleInfo.type === 'index') {
        newTitleInfos[titleId] = {
          loading: false,
          value: {
            ...titleInfo,
            currency: 'PTS'
          }
        }
        continue
      }

      newTitleInfos[titleId] = { loading: false, value: titleInfo }
    }

    // Update state variable only once
    titlesInfo.value = {
      ...titlesInfo.value,
      ...newTitleInfos
    }
  }

  return {
    isInitialized,
    hasError,
    hasSuspiciousLoadingTime,
    eventStream,
    titles,
    loadData,
    getSectorIconById,
    getSectorNameById,
    getSectorById,
    translateKeyEvent,
    getKeyEventIconById,
    getKeyEventById,
    getTitleNameById,
    getTitleById,
    getTitlesById,
    getTitleIconById,
    preIPOCompanies,
    getExchangeByName,
    getExchangeById,
    getCountryByName,
    getCountryByUn,
    getCountryIconByUn,
    getSourceIconByName,
    getSourceByName,
    getMessageSourceTypeIconByName,
    getEntityTypeByValue,
    entityTypes,
    sectors,
    exchanges,
    countries,
    keyEvents,
    lists,
    getListById,
    createNameFromTitleFilter,
    trendingKeyEvents,
    topBuzzTitleIds,
    topBuzzIndexIds,
    titlesInfo,
    userFacingLoadingStates,
    morningBriefingEditions,
    userOpts,
    uiPreferencesGUI,
    transformGUIPreferencesToUiPreferences,
    loadTitleInfo,
    loadTitleInfos,
    loadTrendingKeyEvents: loadTrendingKeyEventsInternal
  }
})
